<template>
  <v-container class="text-center">
    <v-container class="mt-10">
      <h1 class="display-2 font-weight-bold mb-8">
        Miese To Do Liste!
      </h1>
      <p v-if="todos.length === 0">
        Keine Todos ab auf den MC Server!
      </p>
      <p v-else-if="todos.find(v => !v.done)">
        Fertig mit {{ todos.reduce((acc, v) => acc + !!v.done, 0) }} of {{ todos.length }}
      </p>
      <p v-else>
        Alles fertig also mach was anderes produktives!
      </p>
    </v-container>

    <v-container class="mt-10">
      <v-text-field
          v-model="newTodo.text"
          lable="Add new Item"
          append-outer-icon="mdi-plus"
          @keydown.enter="addTodo()"
          @click:append-outer="addTodo()"
      />
      {{ newTodo.text }}

      <v-card
          v-for="item in [{text:'Stel dir vor du wahrst am  29.03. 2022 nicht im DC .'}, ...todos.filter(item => !item.done)]"
          :key="item.text"
          class="my-2"
          @click="item.done =! item.done"
      >
        <v-card-title>
          <h4>{{ item.text }}</h4>
        </v-card-title>

        <v-card-actions>
          <v-btn
              height="40px"
              icon
              width="40px"
              @click.stop="todos = todos.filter(v => v.text !== item.text)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-container>

    <v-container>
      <v-card
          v-for="item in todos.filter(item => item.done)"
          :key="item.text"
          class="my-2"
          color="green"
      >
        <v-card-title>
          <h4>{{ item.text }}</h4>
        </v-card-title>

        <v-card-actions>
          <v-btn
              height="40px"
              icon
              width="40px"
              @click.stop="todos = todos.filter(v => v.text !== item.text)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-btn @click="todos = []" class="mx-2">
      Clear all
    </v-btn>

    <v-btn @click="todos = todos.filter(item => item.done)" class="mx-2">
      Clear unfinished
    </v-btn>

    <v-btn @click="todos = todos.filter(item => !item.done)" class="mx-2">
      Clear finished
    </v-btn>
  </v-container>
</template>

<script>
const todoTemplate = {text: '', done: false};

export default {
  name: 'HelloWorld',

  data: () => ({
    todos: [{text: "wuäh henrik ist nicht da :c", done: true}],
    newTodo: Object.assign({}, todoTemplate),
  }),
  methods: {
    addTodo() {
      if (!this.newTodo.text) return
      this.todos.push(this.newTodo)
      this.newTodo = Object.assign({}, todoTemplate)
    },
    saveState(){
      localStorage.setItem('todos', JSON.stringify(this.todos))
    },
  },
  watch: {
    todos:{
      handler(){
        this.saveState()
      },
      deep: true
    }
  },
  mounted() {
    if (localStorage.todos)
      this.todos = JSON.parse(localStorage.todos)
  }
}
</script>
